import cn from 'clsx'
import { useRouter } from 'next/router'
import NProgress from 'nprogress'
import { useEffect } from 'react'
import fonts from '../fonts'

import { ResizeObserver } from '@juggle/resize-observer'
import Head from 'next/head'
import '../../theme/global.css'
import '../../theme/vendors.css'
import isBrowser from '../helpers/isBrowser'
import useWindowResize from '../hooks/useWindowResize'

NProgress.configure({ showSpinner: false })

// Pollyfill the resize observer
if (isBrowser && !window.ResizeObserver) {
  window.ResizeObserver = ResizeObserver
}

function MyApp ({ Component, pageProps }) {
  const router = useRouter()

  // Whenever the route changes
  useEffect(() => {
    const handleRouteChangeStart = (url, { shallow }) => {
      if (!shallow) {
        NProgress.start()
        document.body.classList.add('loading')
      }
    }
    const handleRouteChangeError = (url, { shallow }) => {
      if (!shallow) {
        NProgress.done()
        document.body.classList.remove('loading')
      }
    }
    const handleRouteChangeComplete = (url, { shallow }) => {
      if (!shallow) {
        NProgress.done()
        document.body.classList.remove('loading')
      }
    }

    router.events.on('routeChangeStart', handleRouteChangeStart)
    router.events.on('routeChangeError', handleRouteChangeError)
    router.events.on('routeChangeComplete', handleRouteChangeComplete)

    return () => {
      router.events.off('routeChangeStart', handleRouteChangeStart)
      router.events.off('routeChangeError', handleRouteChangeError)
      router.events.off('routeChangeComplete', handleRouteChangeComplete)
    }
  }, [])

  useWindowResize(() => {
    const height = window.innerHeight
    document.documentElement.style.setProperty('--window-height', `${height}px`)
  }, true)

  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
      </Head>
      <Component {...pageProps} className={cn(fonts.body.variable, fonts.serif.variable)} />
    </>
  )
}

export default MyApp
