import localFont from '@next/font/local'

const bodyFont = localFont({
  src: './FKGroteskNeue.woff2',
  fallback: ['helvetica', 'arial', 'sans-serif'],
  weight: '100 900',
  variable: '--body-font',
  display: 'swap',
})

const serifFont = localFont({
  src: './FKRomanStandard.woff2',
  fallback: ['times', 'serif'],
  weight: '100 900',
  variable: '--serif-font',
  display: 'swap',
})

const fonts = {
  body: bodyFont,
  serif: serifFont
}

export default fonts
